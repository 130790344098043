import React from 'react'

function PrivacyPolicyPage() {

  return (
    <iframe
      src="privacy policy.pdf"
      width="100%"
    //   height="600px"
      className='!h-screen !w-screen'
      title="PDF Viewer"
    />
  )
}

export default PrivacyPolicyPage