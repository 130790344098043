import banner from "../asset/banner.jpeg"
import bank from "../asset/bank.svg"
import invoice from "../asset/invoice.svg"
import pocket from "../asset/pocket.jpeg"
import apex from "../asset/apex.jpeg"
import shoppingMall from "../asset/classic.jpeg"
import roadsideShop from "../asset/rural.jpeg"
import Marquee from "../mq/marquee"
import { cn } from "../lib/utils"
// import { useMediaQuery } from ".../hooks/use-media-query"
// import { Button } from "../ui/button"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from "../components/ui/dialog"
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger
} from "../components/ui/drawer"
import { Input } from "../components/ui/input"
import { Label } from "../components/ui/label"
import { useMediaQuery } from "@raddix/use-media-query"
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Textarea } from "../components/ui/textarea"
import { useEffect, useState } from "react"
import axios from "axios"
import { Link } from "react-router-dom"

export function DrawerDialogDemo() {
  const [open, setOpen] = useState(false)
  const isDesktop = useMediaQuery("(min-width: 768px)")

  if (isDesktop) {
    return (
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogTrigger asChild>
          <button className="bg-emerald-700 select-none h-[45px] px-[30px] rounded-full text-white">
            Get Started
          </button>
        </DialogTrigger>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>Schedule a demo</DialogTitle>
            <DialogDescription>
              Fill in your company details. This information wil be used for profiling and subsequnt comunication
            </DialogDescription>
          </DialogHeader>
          <ProfileForm />
        </DialogContent>
      </Dialog>
    )
  }

  return (
    <Drawer open={open} onOpenChange={setOpen}>
      <DrawerTrigger asChild>
        <button className="bg-emerald-700 select-none h-[45px] px-[30px] rounded-full text-white">
          Get Started
        </button>
      </DrawerTrigger>
      <DrawerContent>
        <DrawerHeader className="text-left">
          <DrawerTitle>Schedule a demo</DrawerTitle>
          <DrawerDescription>
            Fill in your company details. This information wil be used for profiling and subsequnt comunication
          </DrawerDescription>
        </DrawerHeader>
        <ProfileForm className="px-4" />
        <DrawerFooter className="pt-2">
          <DrawerClose asChild>
            <button variant="outline">Cancel</button>
          </DrawerClose>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}

function ProfileForm({ className }) {
  const [loading, setloading] = useState(false)
  const [open, setopen] = useState(false)
  const [failed, setfailed] = useState(false)

  const validationSchema = yup.object().shape({
    company_name: yup.string().required("Fullname is required"),
    email: yup.string().email().required("Email is required"),
    phone: yup.string().required("Phone is required"),
    description: yup.string(),
  });

  const {
    register,
    formState: { errors, isValid },
    watch,
    trigger,
    getValues,
    handleSubmit,
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      company_name: "",
      email: "",
      phone: "",
      description: "",
    },
  });

  const submit_handler = (data) => {
    setloading(true)
    axios.post("https://admin.scriipp.com/api/home/contact/schedule-demo/", {
      ...data
    }).then((res) => {
      reset()
      setopen(true)
    }).catch(() => {
      setfailed(true)
    }).finally(() => {
      setloading(false)
    })
  }

  useEffect(() => {
    if(open == false){
      setfailed(false)
    }
  }, [open])
  

  return (
    <>

      <Dialog open={open} onOpenChange={setopen}>
        <DialogContent className="sm:max-w-[350px] flex flex-col gap-[20px] justify-center items-center">
          {!failed ? <div class="w-[50px] aspect-square text-[#16a34a] rounded-full p-[10px] bg-green-200"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" class="oc sf axy"><path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5"></path></svg></div>
          :
          <div class="w-[50px] aspect-square text-[#a31616] rounded-full p-[10px] bg-red-200"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
          </svg>
          </div>}
          {!failed ? <div className="text-[14px] flex flex-col gap-[10px] justify-center items-center text-center">
            <span className="text-[16px] font-[700]">Successfully Requested!</span>
            <span className="font-[400] text-zinc-500">Your request has been submitted successfully. You will receive a confirmation email shortly to verify that we have received your request. </span>
          </div>
          :
          <div className="text-[14px] flex flex-col gap-[10px] justify-center items-center text-center">
            <span className="text-[16px] font-[700]">Oops Failed! Try again</span>
            <span className="font-[400] text-zinc-500">Your request could not be submitted. Please try again later, and if the problem persists, contact our support team (support@stohb.com) for assistance.</span>
          </div>}
        </DialogContent>
      </Dialog>
      <form
        onSubmit={handleSubmit(submit_handler)} className={cn("grid grid-cols-2 items-start gap-4", className)}>
        <div className="grid gap-2 col-span-2">
          <Label htmlFor="name">Business Name</Label>
          <Input {...register("company_name")} id="name" placeholder="XYZ Limited" />
        </div>
        <div className="grid gap-2">
          <Label htmlFor="email">Business Email</Label>
          <Input {...register("email")} type="email" id="email" placeholder="contact@xyzlimited.com" />
        </div>
        <div className="grid gap-2">
          <Label htmlFor="phone">Business Phone</Label>
          <Input {...register("phone")} type="phone" id="phone" placeholder="+234 900 000 0000" />
        </div>
        <div className="grid gap-2 col-span-2">
          <Label htmlFor="desc">Business Description</Label>
          <Textarea {...register("description")} id="desc" placeholder="Optional" />
        </div>
        <div className="col-span-full flex justify-start">
          <button className="bg-emerald-700 select-none text-[14px] h-[40px] px-[20px] rounded-full text-white">
            {loading ? <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
              <path fill="white" d="M12,4a8,8,0,0,1,7.89,6.7A1.53,1.53,0,0,0,21.38,12h0a1.5,1.5,0,0,0,1.48-1.75,11,11,0,0,0-21.72,0A1.5,1.5,0,0,0,2.62,12h0a1.53,1.53,0,0,0,1.49-1.3A8,8,0,0,1,12,4Z">
                <animateTransform attributeName="transform" dur="0.75s" repeatCount="indefinite" type="rotate" values="0 12 12;360 12 12" />
              </path>
            </svg> :
              "Send Request"}
          </button>
        </div>
      </form>
    </>
  )
}


function HomePage() {
  const product = [
    {
      title: "Accounts payable",
      description: "Manage expenses, and reconcile bills",
      icon: <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24">
        <path fill="#9e0000" d="m6.342 17l3.25-5l-3.25-5l3.25 5l-.512.775q-.513.775-1.113 1.713t-1.112 1.725zM4.54 18l3.846-6l-3.847-6h10.685l4.163 5.89q-.309-.082-.656-.124t-.669-.035L14.673 7h-8.33l3.25 5l-3.25 5h5.367q-.056.25-.056.49l-.002.51zm12.832 1.827l2.84-2.796l-.626-.627l-2.214 2.182l-.955-.975l-.627.633zM18 22.115q-1.671 0-2.835-1.164Q14 19.787 14 18.116t1.165-2.836T18 14.116t2.836 1.164T22 18.116q0 1.67-1.164 2.835Q19.67 22.116 18 22.116" />
      </svg>
    },
    {
      title: "Accounts recievable",
      description: "Send Invoices, and get paid",
      icon: <svg xmlns="http://www.w3.org/2000/svg" className=" rotate-180" width="40" height="40" viewBox="0 0 24 24">
        <path fill="#388604" d="m6.342 17l3.25-5l-3.25-5l3.25 5l-.512.775q-.513.775-1.113 1.713t-1.112 1.725zM4.54 18l3.846-6l-3.847-6h10.685l4.163 5.89q-.309-.082-.656-.124t-.669-.035L14.673 7h-8.33l3.25 5l-3.25 5h5.367q-.056.25-.056.49l-.002.51zm12.832 1.827l2.84-2.796l-.626-.627l-2.214 2.182l-.955-.975l-.627.633zM18 22.115q-1.671 0-2.835-1.164Q14 19.787 14 18.116t1.165-2.836T18 14.116t2.836 1.164T22 18.116q0 1.67-1.164 2.835Q19.67 22.116 18 22.116" />
      </svg>
    },
    {
      title: "Customer management",
      description: "Seamless customer management and grouping",
      icon: <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24">
        <path fill="#666666" d="M10.8 19.916q1.106-1.949 2.789-2.682Q15.27 16.5 16.5 16.5q.517 0 .98.071q.464.071.912.202q.658-.854 1.133-2.098T20 12q0-3.35-2.325-5.675T12 4T6.325 6.325T4 12q0 1.298.384 2.448q.383 1.15 1.035 2.102q.948-.558 1.904-.804t2.004-.246q.627 0 1.22.099q.594.099.972.209q-.286.184-.52.373q-.233.188-.472.427q-.185-.05-.532-.08q-.347-.028-.668-.028q-.858 0-1.703.214q-.845.213-1.57.64q.935 1.05 2.162 1.693q1.228.643 2.584.868M12.003 21q-1.866 0-3.51-.708q-1.643-.709-2.859-1.924t-1.925-2.856T3 12.003t.709-3.51Q4.417 6.85 5.63 5.634t2.857-1.925T11.997 3t3.51.709q1.643.708 2.859 1.922t1.925 2.857t.709 3.509t-.708 3.51t-1.924 2.859t-2.856 1.925t-3.509.709M9.5 13q-1.258 0-2.129-.871T6.5 10t.871-2.129T9.5 7t2.129.871T12.5 10t-.871 2.129T9.5 13m0-1q.817 0 1.409-.591q.591-.592.591-1.409t-.591-1.409Q10.317 8 9.5 8t-1.409.591Q7.5 9.183 7.5 10t.591 1.409Q8.683 12 9.5 12m7 2.385q-1.001 0-1.693-.692T14.116 12t.691-1.693t1.693-.691t1.693.691t.691 1.693t-.691 1.693t-1.693.692M12 12" />
      </svg>
    },
  ]

  const features = [
    { title: "Effortless Bank Reconciliations", icon: bank },
    { title: "Forward Invoices & Bills via Email", icon: invoice },
    { title: "Easy Customer Management", icon: bank },
    { title: "Organize Customers into Groups", icon: bank },
    { title: "Insightful Financial Reporting", icon: bank },
    { title: "Analyze Debtors and Creditors", icon: bank },
  ];

  const firstRow = [
    {
      image: apex,
      quote: "Stohb has been the easiest way to optimize our cashflow. The team is very responsive and hands on! ",
      company: "CEO, Royal Schools",
      person: "Mr. Adetunji Micah",
      industry: "Education"
    },
    {
      image: roadsideShop,
      quote: "Stohb has transformed the way we handle our daily sales and inventory. It's incredibly easy to use and saves us so much time!",
      company: "Owner, Mr. Samuel Okeke",
      person: "Mr. Amina Okeke",
      industry: "Retail"
    },
    {
      image: shoppingMall,
      quote: "With Stohb, managing our finances has never been simpler. The insights we gain are invaluable for our growth!",
      company: "Manager, Elegant Emporium",
      person: "Mrs. Adenike Mariam",
      industry: "Fashion Retail"
    }
  ]

  return (
    <div className="w-screen h-max selection:bg-emerald-100">

      <div className="z-20 fixed w-screen md:pt-[10px] md:px-[20px]">
        <div className="bg-white/60 overflow-hidden backdrop-blur-sm drounded-[30px] w-full h-[100px] flex items-center justify-between px-[20px] md:px-[30px]">
          <span className="text-[30px] select-none">
            Stohb
          </span>
          <div className="flex justify-end items-center gap-[24px]">
            <Link className="text-[14px] text-zinc-500" to="/terms">Terms</Link>
            <Link className="text-[14px] text-zinc-500" to="/privacy-policy">Privacy Policy</Link>
          <DrawerDialogDemo />
          </div>
          {/* <div>

        </div> */}
        </div>
      </div>
      <div className="h-max pt-[200px] flex flex-col pb-[50px] gap-[30px] items-start px-[20px] md:px-[50px]">
        <span className="text-[40px] md:text-[50px] md:max-w-[600px] font-[600] leading-tight select-none">Manage <span className="bg-emerald-100">payment & expenses </span>on the fly like never before</span>
        <span className="text-[20px] md:max-w-[600px] text-zinc-500">Stohb helps businesses manage and audit finacial transactions, manage customers and in a very to adopt manner.</span>
        <DrawerDialogDemo />
        <span>Manage expenses, and reconcile bills, Send Invoices, and get paid, & Seamless customer management and grouping</span>
      </div>
      <div className="h-[400px] md:h-screen overflow-hidden">
        <img src={banner} className="w-full select-none brightness-75 h-full object-cover object-center" />
      </div>
      <div className="pb-[50px] pt-[100px] px-[20px] md:px-[50px] flex flex-col gap-[40px]">
        <span className="text-[50px] max-w-[600px] font-[600] leading-none select-none">Product</span>
        <span className="text-[20px] max-w-[600px] text-zinc-500">
          Stohb makes it very easy for businesses to gain control of its cash flow, send invoices and manage customers</span>
        <hr />
        <div className="flex md:flex-row flex-col w-full justify-between items-start md:items-center gap-[40px] md:gap-[10px]">
          {product.map((product) => <div className="flex select-none items-center gap-[10px] justify-start">
            <div>{product.icon}</div>
            <div className="flex flex-col">
              <span className="font-[600] text-[20px]">{product.title}</span>
              <span className="text-zinc-400 text-[16px]">{product.description}</span>
            </div>
          </div>)}
        </div>
      </div>
      <div className="pb-[50px] pt-[200px] h-max bg-zinc-950 text-white px-[20px] md:px-[50px] justify-end flex flex-col gap-[40px]">
        <span className="text-[50px] max-w-[600px] font-[600] leading-none select-none">Features</span>
        <span className="text-[20px] w-full max-w-[700px] selection:text-black text-zinc-300">Stohb offers numerous amazing features that elevate your business finances. We simplify accounting terminologies and concepts to enhance understanding and provide clearer interpretations of numbers and insights.</span>
        <hr className="border-zinc-600" />
        <div className="grid grid-cols-1 gap-[40px] md:grid-cols-2">
          <div className="flex flex-col w-full justify-start items-start gap-[40px]">
            {features.map((feature) => <div className="flex select-none flex-col items-start text-zinc-500 hover:pl-[20px] hover:text-emerald-500 transition-all duration-500 delay-100 cursor-pointer gap-[10px] justify-start">
              <span className="font-[300] tracking-wide text-[20px] ">{feature.title}</span>
            </div>)}
          </div>
          <div className="flex select-none justify-end items-center">
            <img src={pocket} className="w-full brightness-75 h-full md:h-[600px] object-cover object-center" />
          </div>
        </div>
      </div>
      <div className="pb-[50px] pt-[100px] px-[20px] md:px-[50px] flex flex-col gap-[40px]">
        <span className="text-[50px] max-w-[600px] font-[600] leading-none select-none">Try Stohb now</span>
        <div className="flex flex-col md:flex-row gap-[40px] items-start md:items-center justify-between">
          <span className="text-[20px] max-w-[600px] text-zinc-500">We are looking at patnering with businesses who are ready to level up their business operation with a seamless finance management</span>
          <DrawerDialogDemo />
        </div>
      </div>
      <div className="w-full h-max pt-[100px] flex gap-[40px] dbg-zinc-100 flex-col">
        <div className="flex px-[20px] md:px-[50px] gap-[40px] flex-col">
          <span className="text-[50px] max-w-[600px] font-[600] leading-none select-none">Still not sure?</span>
          <span className="text-[20px] max-w-[600px] text-zinc-500">Listen to what our early amazing users have to say about us. This should change your mind.</span>
          <hr className="border-zinc-300" />
        </div>
        <Marquee pauseOnHover className="md:[--duration:40s] [--duration:20s]">
          {firstRow.map((review) => (
            <div className="w-max md:w-screen select-none h-[500px] grid grid-cols-2 gap-[10px]"
            >
              <img
                className="w-screen md:w-full rounded-[20px] overflow-hidden h-full object-cover"
                src={review.image}
              />

              <div className="p-[30px] border rounded-[20px] w-screen md:w-full h-full flex flex-col justify-between items-start">
                <div className="py-[10px] select-none px-[20px] bg-emerald-50 text-emerald-600 rounded-full">{review.industry}</div>
                <div className="flex flex-col gap-[10px]">
                  <span className="text-[30px] font-[600] text-emerald-800">"{review.quote}"</span>
                  <span className="text-[20px] text-emerald-600 font-300">{review.company}</span>
                </div>
              </div>
            </div>
          ))}
        </Marquee>
      </div>
      <div className="w-full select-none h-[100px] flex items-center justify-center text-zinc-400 text-center">
        &copy; Copyright stohb limited 2024
      </div>
    </div>
  );
}

export default HomePage;
